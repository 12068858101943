import * as React from "react"
import '../style.css'
import PPTCarousel from "../components/carousel";
import Layout from "../components/layout"

const PPTDisplay = ({location}) => {
  console.log(location)
  return (
    <Layout>
            {location.state?<h1>{location.state.title}</h1>:null}
            {location.state?<h2 style={{marginBottom:'40px'}}>Author: {location.state.presenter}</h2>:null}
            {location.state? <PPTCarousel slides={location.state.slides}/> : null}
            
            {location.state?
              location.state.pptDownloads.ppt ? 
                <p>Download the slides as a <a href={`/static/${location.state.pptDownloads.ppt}`} download target='_blank' rel="noreferrer">ppt</a> or <a href={`/static/${location.state.pptDownloads.pptx}`} download target='_blank' rel="noreferrer">pptx</a></p>
                :
                null
              :null
            }
    </Layout>
  )
}

export default PPTDisplay
